import { useEffect, useState } from 'react';

declare global {
  interface Window {
    Cookiebot: {
      consent: {
        method: string;
      };
    };
  }
}

export const CookiebotClient = () => {
  const [hasUserInteracted, setHasUserInteracted] = useState(true);
  useEffect(() => {
    const handleDialogInit = () => {
      if (window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.method) {
        setHasUserInteracted(true);
      } else {
        setHasUserInteracted(false);
      }
    };

    const handleCookieChange = () => {
      setHasUserInteracted(true);
    };

    window.addEventListener('CookiebotOnDialogInit', handleDialogInit);
    window.addEventListener('CookiebotOnAccept', handleCookieChange);
    window.addEventListener('CookiebotOnDecline', handleCookieChange);

    return () => {
      window.removeEventListener('CookiebotOnDialogInit', handleDialogInit);
      window.removeEventListener('CookieConsentDeclaration', handleCookieChange);
      window.removeEventListener('CookiebotOnDecline', handleCookieChange);
    };
  }, []);

  useEffect(() => {
    document.body.style.maxHeight = hasUserInteracted ? 'auto' : '100vh';
    document.body.style.overflow = hasUserInteracted ? 'auto' : 'hidden';
  }, [hasUserInteracted]);

  return hasUserInteracted ? null : <div className="cookie-overlay"></div>;
};
