import React, { FC } from 'react';

import { Header, Footer } from '@components/Organisms';

import { StyledMain } from './styled/StyledMain';
import { CookiebotClient } from '@components/Molecules/CookiebotClient';

export const Layout: FC<any> = ({
  children,
  path,
  pageProps = {},
  headerInverted = false,
  displayHeader = true,
  gradientBackground = false,
  showCtaInHeader = true,
}) => {
  const salesCountdownIsDisplayed =
    pageProps?.data?.page?.salesCountdown && pageProps?.salesCountdownData?.display;

  return (
    <>
      <CookiebotClient />
      {displayHeader && (
        <Header path={path} inverted={headerInverted} showCtaInHeader={showCtaInHeader} />
      )}
      <StyledMain
        $gradientBackground={gradientBackground}
        $salesCountdownIsDisplayed={salesCountdownIsDisplayed}
      >
        {children}
      </StyledMain>
      <Footer footerData={pageProps?.footerData} />
    </>
  );
};
